import io from "socket.io-client";

const state = {
    username: null,
    users: [],
    messages: [],
};

const mutations = {
    setUsername(state, username) {
        state.username = username;
    },
    setUsers(state, users) {
        state.users = users;
    },
    addMessage(state, message) {
        state.messages.push(message);
    },
    clearMessages(state) {
        state.messages = [];
    },
};

const actions = {
    connectToChat({ commit, dispatch }) {
        const socket = io(process.env.VUE_APP_CHAT_API_URL);

        socket.on("connect", () => {
            console.log("Connected to chat server");
        });

        socket.on("userList", (users) => {
            commit("setUsers", users);
        });

        socket.on("chatMessage", (message) => {
            commit("addMessage", message);
        });

        socket.on("disconnect", () => {
            console.log("Disconnected from chat server");
            dispatch("resetChatState");
        });

        return new Promise((resolve) => {
            socket.on("connect", () => {
                resolve(socket);
            });
        });
    },

    setUsername({ commit }, username) {
        commit("setUsername", username);
    },

    sendMessage(_, { socket, message }) {
        socket.emit("chatMessage", message);
    },

    resetChatState({ commit }) {
        commit("setUsername", null);
        commit("setUsers", []);
        commit("clearMessages");
    },
};

const getters = {
    isAuthenticated(state) {
        return !!state.username;
    },

    currentUser(state) {
        return state.users.find((user) => user.username === state.username);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
