import axios from "axios";

const state = {
    tasks: [],
};

const getters = {
    allTasks: (state) => state.tasks,
};

const actions = {
    async fetchTasks({ commit }) {
        const headers = { "Content-Type": "application/json" };
        const response = await axios.get("https://server.go-platform.com/data/tasks", {
            headers,
        });

        commit("setTasks", response.data);
    },
    async addTask({ commit }, task) {
        try {
            const newTask = await axios.post("https://localhost:3000/post/tasks", {
                task,
            });
            commit("newTask", newTask);
        } catch (error) {
            console.log(error);
        }
    },
    deleteTask({ commit }, id) {
        commit("removeTask", id);
    },
    updateTask({ commit }, updTask) {
        commit("updateTask", updTask);
    },
};

const mutations = {
    setTasks: (state, tasks) => (state.tasks = tasks),
    newTask: (state, task) => state.tasks.unshift(task),
    removeTask: (state, id) => (state.tasks = state.tasks.filter((task) => task.id !== id)),
    updateTask: (state, updTask) => {
        const index = state.tasks.findIndex((task) => task.id === updTask.id);
        if (index !== -1) {
            state.tasks.splice(index, 1, updTask);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

/*[
            {   id:1, status:'do', cardTitle:'revise code 1', label:'important',
                members:[{name:'abdellah aouf', addedBy:'abdelghani bahri', date: '22/04/2023 at 18:02'},
                    {name:'abdellah aouf', addedBy:'abdelghani bahri', date: '22/04/2023 at 18:02'},],
                attachement:[{name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},
                    {name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},
                    {name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},],
                comments:[{text:'this is a comment', member:'abdellah aouf', date:'01/11/2021 at 14:42'}],
                activity:[{type:'comment', member:'abdellah aouf', date:'26/7/2022 at 9:37'}]
            },
            {   id:2, status:'doing', cardTitle:'revise code2', label:'important',
                members:[{name:'abdellah aouf', addedBy:'abdelghani bahri', date: '22/04/2023 at 18:02'},
                    {name:'abdellah aouf', addedBy:'abdelghani bahri', date: '22/04/2023 at 18:02'},],
                attachement:[{name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},
                    {name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},
                    {name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},],
                comments:[{text:'this is a comment', member:'abdellah aouf', date:'01/11/2021 at 14:42'}],
                activity:[{type:'comment', member:'abdellah aouf', date:'26/7/2022 at 9:37'}]
            },
            {   id:3, status:'done', cardTitle:'revise code 3', label:'important',
                members:[{name:'abdellah aouf', addedBy:'abdelghani bahri', date: '22/04/2023 at 18:02'},
                    {name:'abdellah aouf', addedBy:'abdelghani bahri', date: '22/04/2023 at 18:02'},],
                attachement:[{name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},
                    {name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},
                    {name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},],
                comments:[{text:'this is a comment', member:'abdellah aouf', date:'01/11/2021 at 14:42'}],
                activity:[{type:'comment', member:'abdellah aouf', date:'26/7/2022 at 9:37'}]
            },
            {   id:4, status:'do', cardTitle:'revise code 4', label:'important',
                members:[{name:'abdellah aouf', addedBy:'abdelghani bahri', date: '22/04/2023 at 18:02'},
                    {name:'abdellah aouf', addedBy:'abdelghani bahri', date: '22/04/2023 at 18:02'},],
                attachement:[{name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},
                    {name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},
                    {name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},],
                comments:[{text:'this is a comment', member:'abdellah aouf', date:'01/11/2021 at 14:42'}],
                activity:[{type:'comment', member:'abdellah aouf', date:'26/7/2022 at 9:37'}]
            },
            {   id:5, status:'doing', cardTitle:'revise code 5', label:'important',
                members:[{name:'abdellah aouf', addedBy:'abdelghani bahri', date: '22/04/2023 at 18:02'},
                    {name:'abdellah aouf', addedBy:'abdelghani bahri', date: '22/04/2023 at 18:02'},],
                attachement:[{name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},
                    {name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},
                    {name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},],
                comments:[{text:'this is a comment', member:'abdellah aouf', date:'01/11/2021 at 14:42'}],
                activity:[{type:'comment', member:'abdellah aouf', date:'26/7/2022 at 9:37'}]
            },
            {   id:6, status:'done', cardTitle:'revise code 6', label:'important',
                members:[{name:'abdellah aouf', addedBy:'abdelghani bahri', date: '22/04/2023 at 18:02'},
                    {name:'abdellah aouf', addedBy:'abdelghani bahri', date: '22/04/2023 at 18:02'},],
                attachement:[{name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},
                    {name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},
                    {name:'../../assets/images/candidate-image.png', member: 'abdellah aouf', date:'22/04/2023 at 18:25'},],
                comments:[{text:'this is a comment', member:'abdellah aouf', date:'01/11/2021 at 14:42'}],
                activity:[{type:'comment', member:'abdellah aouf', date:'26/7/2022 at 9:37'}]
            },
        ];*/
