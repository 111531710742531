export const disclaimer = `<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:26px;font-family:"Arial",sans-serif;color:black;'>DISCLAIMER</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:15.75pt;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:15.75pt;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Last updated&nbsp;January 1st, 2024</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;text-align:justify;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;text-align:justify;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>WEBSITE DISCLAIMER</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:15px;font-family:"Arial",sans-serif;color:#595959;'>The information provided by&nbsp;GO PLATFORM&nbsp;(&ldquo;we,&rdquo; &ldquo;us&rdquo;, or &ldquo;our&rdquo;) on&nbsp;go-platform.com&nbsp;(the &ldquo;Site&rdquo;)&nbsp;and our mobile application&nbsp;is for general informational purposes only. All information on the Site&nbsp;and our mobile application&nbsp;is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site&nbsp;or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE&nbsp;OR OUR MOBILE APPLICATION&nbsp;OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE&nbsp;AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE&nbsp;AND OUR MOBILE APPLICATION&nbsp;AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE&nbsp;AND OUR MOBILE APPLICATION&nbsp;IS SOLELY AT YOUR OWN RISK.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>EXTERNAL LINKS DISCLAIMER<br>&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:15px;font-family:"Arial",sans-serif;color:#595959;'>The&nbsp;</span><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Site&nbsp;and our mobile application&nbsp;may contain (or you may be sent through the Site&nbsp;or our mobile application) links</span><span style='font-size:15px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>PROFESSIONAL DISCLAIMER</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:15px;font-family:"Arial",sans-serif;color:#595959;'>The Site cannot and does not contain&nbsp;hiring and staffing&nbsp;advice. The&nbsp;hiring and staffing&nbsp;information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of&nbsp;hiring and staffing&nbsp;advice. THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THIS SITE&nbsp;OR OUR MOBILE APPLICATION&nbsp;IS SOLELY AT YOUR OWN RISK.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>AFFILIATES DISCLAIMER</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>The Site and our mobile application may contain links to affiliate websites, and we receive an affiliate commission for any purchases made by you on the affiliate website using such links.&nbsp;</span></p>`;

export const privacy = `<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:26px;font-family:"Arial",sans-serif;color:black;'>PRIVACY NOTICE</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Last updated&nbsp;January 1<sup>st</sup>, 2024</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>This privacy notice for our users&nbsp;(&quot;<strong>we</strong>,&quot; &quot;<strong>us</strong>,&quot; or &quot;<strong>our</strong>&quot;), describes how and why we might collect, store, use, and/or share (&quot;<strong>process</strong>&quot;) your information when you use our services (&quot;<strong>Services</strong>&quot;), such as when you:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Questions or concerns?&nbsp;</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>SUMMARY OF KEY POINTS</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our&nbsp;</span></em></strong><strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>table of contents</span></em></strong><strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;below to find the section you are looking for.</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>What personal information do we process?</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about&nbsp;</span><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>personal information you disclose to us</span></u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Do we process any sensitive personal information?</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;We do not process sensitive personal information.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Do we receive any information from third parties?</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;We may receive information from public databases, marketing partners, social media platforms, and other outside sources. Learn more about&nbsp;</span><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>information collected from other sources</span></u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>How do we process your information?</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about&nbsp;</span><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>how we process your information</span></u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>In what situations and with which&nbsp;parties do we share personal information?</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;We may share information in specific situations and with specific third parties. Learn more about&nbsp;</span><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>when and with whom we share your personal information</span></u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>What are your rights?</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about&nbsp;</span><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>your privacy rights</span></u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>How do you exercise your rights?</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;The easiest way to exercise your rights is by submitting a&nbsp;</span><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>data subject access request</span></u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Want to learn more about what we do with any information we collect?&nbsp;</span><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>Review the privacy notice in full</span></u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>1. WHAT INFORMATION DO WE COLLECT?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:17px;font-family:"Arial",sans-serif;color:black;'>Personal information you disclose to us</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>In Short:&nbsp;</span></em></strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We collect personal information that you provide to us.</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We collect personal information that you voluntarily provide to us when you&nbsp;register on the Services,&nbsp;express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Sensitive Information.</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;We do not process sensitive information.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:17px;font-family:"Arial",sans-serif;color:black;'>Information automatically collected</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>In Short:&nbsp;</span></em></strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device characteristics &mdash; is collected automatically when you visit our Services.</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Like many businesses, we also collect information through cookies and similar technologies.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>2. HOW DO WE PROCESS YOUR INFORMATION?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>In Short:&nbsp;</span></em></strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>In Short:</span></em></strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;We may share information in specific situations described in this section and/or with the following third parties.</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We&nbsp;may need to share your personal information in the following situations:</span></p>
<ul type="square" style="margin-bottom:0cm;">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:#6C7794;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Business Transfers.</span></strong><span style='font-size:14px;font-family:     "Arial",sans-serif;color:#595959;'>&nbsp;We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:#6C7794;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Affiliates.&nbsp;</span></strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We will not share your information with our affiliates, in which case we will require those affiliates to&nbsp;honor&nbsp;this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:#6C7794;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Business Partners.</span></strong><span style='font-size:15px;font-family:     "Arial",sans-serif;color:#595959;'>&nbsp;We will not share your information with our business partners to offer you certain products, services, or promotions.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>In Short:</span></em></strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;We may use cookies and other tracking technologies to collect and store your information.</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>In Short:&nbsp;</span></em></strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>In Short:&nbsp;</span></em></strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We may transfer, store, and process your information in countries other than your own.</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Our servers are located in. If you are accessing our Services from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see&nbsp;&quot;</span><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#003AFA;'>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&quot;&nbsp;above), in&nbsp;and other countries.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>7. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>In Short:&nbsp;</span></em></strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We keep your information for as long as necessary to&nbsp;fulfill&nbsp;the purposes outlined in this privacy notice unless otherwise required by law.</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>When we have no ongoing legitimate business need to process your personal information, we will either delete or&nbsp;anonymize&nbsp;such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>8. DO WE COLLECT INFORMATION FROM MINORS?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>In Short:</span></em></strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;We do not knowingly collect data from or market to children under 18 years of age.</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data, we may have collected from children under age 18, please contact us at&nbsp;</span><a href="mailto:contact@go-platform.dz"><span style='font-size:14px;font-family:"Arial",sans-serif;'>contact@go-platform.dz</span></a></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>9. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>In Short:</span></em></strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp; You may review, change, or terminate your account at any time.</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Withdrawing your consent:</span></u></strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section &quot;</span><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&quot;&nbsp;below.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,&nbsp;when applicable law allows,&nbsp;will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:17px;font-family:"Arial",sans-serif;color:black;'>Account Information</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:15px;font-family:"Arial",sans-serif;color:#595959;'>If you would at any time like to review or change the information in your account or terminate your account, you can:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for&nbsp;recognizing&nbsp;and implementing DNT signals has been&nbsp;finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>11. DO WE MAKE UPDATES TO THIS NOTICE?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>In Short:&nbsp;</span></em></strong><em><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Yes, we will update this notice as necessary to stay compliant with relevant laws.</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We may update this privacy notice from time to time. The updated version will be indicated by an updated&nbsp;&quot;Revised&quot;&nbsp;date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>If you have questions or comments about this notice, you may&nbsp;contact us by email at:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><a href="mailto:contact@go-platform.dz"><span style='font-size:14px;font-family:"Arial",sans-serif;'>contact@go-platform.dz</span></a></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please fill out and submit a&nbsp;</span><u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>data subject access request</span></u><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;</span></p>`;
export const cookie = `<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:26px;font-family:"Arial",sans-serif;color:black;'>COOKIE POLICY</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Last updated&nbsp;December 07, 2023</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>This Cookie Policy explains how&nbsp;GO PLATFORM&nbsp;(&quot;<strong>Company</strong>,&quot; &quot;<strong>we</strong>,&quot; &quot;<strong>us</strong>,&quot; and &quot;<strong>our</strong>&quot;) uses cookies and similar technologies to recognize you when you visit our website at &nbsp;</span><a href="https://go-platform.com/" target="_blank"><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>https://go-platform.com</span></a><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;(&quot;<strong>Website</strong>&quot;). It explains what these technologies are and why we use them, as well as your rights to control our use of them.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>What are cookies?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Cookies set by the website owner (in this case,&nbsp;GO PLATFORM) are called &quot;first-party cookies.&quot; Cookies set by parties other than the website owner are called &quot;third-party cookies.&quot; Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>Why do we use cookies?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We use first-&nbsp;and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate, and we refer to these as &quot;essential&quot; or &quot;strictly necessary&quot; cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties.&nbsp;Third parties serve cookies through our website for advertising, analytics, and other purposes.&nbsp;This is described in more detail below.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>How can I control cookies?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>The specific types of first- and third-party cookies served through our website and the purposes they perform are described in the table below (please note that the specific&nbsp;cookies served may vary depending on the specific Online Properties you visit):</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>How can I control cookies on my browser?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>As the means by which you can refuse cookies through your web browser controls vary from browser to browser, you should visit your browser&apos;s help menu for more information. The following is information about how to manage cookies on the most popular browsers:</span></p>
<ul type="square" style="margin-bottom:0cm;">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:#6C7794;line-height:normal;background:white;'><span style="color:windowtext;"><a href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies" target="_blank"><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>Chrome</span></a></span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:#6C7794;line-height:normal;background:white;'><span style="color:windowtext;"><a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank"><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>Internet Explorer</span></a></span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:#6C7794;line-height:normal;background:white;'><span style="color:windowtext;"><a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US" target="_blank"><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>Firefox</span></a></span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:#6C7794;line-height:normal;background:white;'><span style="color:windowtext;"><a href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac" target="_blank"><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>Safari</span></a></span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:#6C7794;line-height:normal;background:white;'><span style="color:windowtext;"><a href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd" target="_blank"><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>Edge</span></a></span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:#6C7794;line-height:normal;background:white;'><span style="color:windowtext;"><a href="https://help.opera.com/en/latest/web-preferences/" target="_blank"><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>Opera</span></a></span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please visit:</span></p>
<ul type="square" style="margin-bottom:0cm;">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:#6C7794;line-height:normal;background:white;'><span style="color:windowtext;"><a href="http://www.aboutads.info/choices/" target="_blank"><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>Digital Advertising Alliance</span></a></span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:#6C7794;line-height:normal;background:white;'><span style="color:windowtext;"><a href="https://youradchoices.ca/" target="_blank"><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>Digital Advertising Alliance of Canada</span></a></span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:#6C7794;line-height:normal;background:white;'><span style="color:windowtext;"><a href="http://www.youronlinechoices.com/" target="_blank"><span style='font-size:14px;font-family:"Arial",sans-serif;color:#3030F1;'>European Interactive Digital Advertising Alliance</span></a></span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>What about other tracking technologies, like web beacons?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Cookies are not the only way&nbsp;to recognize or track visitors to a website. We may use other, similar technologies from time to time, like web beacons (sometimes called &quot;tracking pixels&quot; or &quot;clear gifs&quot;). These are tiny graphics files that contain a unique identifier that enables us to recognize when someone has visited our website&nbsp;or opened an email including them. This allows us, for example, to monitor&nbsp;the traffic patterns of users from one page within a website to another, to deliver or communicate with cookies, to understand whether you have come to the website from an online advertisement displayed on a third-party website, to improve site performance, and to measure the success of email marketing campaigns. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>Do you use Flash cookies or Local Shared Objects?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Websites may also use so-called &quot;Flash Cookies&quot; (also known as Local Shared Objects or &quot;LSOs&quot;) to, among other things, collect and store information about your use of our services, fraud prevention, and for other site operations.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>If you do not want Flash Cookies stored on your computer, you can adjust the settings of your Flash player to block Flash Cookies storage using the tools contained in the&nbsp;</span><a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html#_blank"><span style='font-size:15px;font-family:"Arial",sans-serif;color:#3030F1;'>Website Storage Settings Panel</span></a><span style='font-size:15px;font-family:"Arial",sans-serif;color:#595959;'>. You can also control Flash Cookies by going to the&nbsp;</span><a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html#_blank"><span style='font-size:15px;font-family:"Arial",sans-serif;color:#3030F1;'>Global Storage Settings Panel</span></a><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>&nbsp;and following the instructions (which may include instructions that explain, for example, how to delete existing Flash Cookies (referred to &quot;information&quot; on the Macromedia site), how to prevent Flash LSOs from being placed on your computer without your being asked, and (for Flash Player 8 and later) how to block Flash Cookies that are not being delivered by the operator of the page you are on at the time).</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Please note that setting the Flash Player to restrict or limit acceptance of Flash Cookies may reduce or impede the functionality of some Flash applications, including, potentially, Flash applications used in connection with our services or online content.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>Do you serve targeted advertising?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>Third parties may serve cookies on your computer or mobile device to serve advertising through our website. These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness of advertisements. They can accomplish this by using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you. The information collected through this process does not enable us or them to identify your name, contact details, or other details that directly identify you unless you choose to provide these.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>How often will you update this, Cookie Policy?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>We may update&nbsp;this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>The date at the top of this Cookie Policy indicates when it was last updated.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><strong><span style='font-size:19px;font-family:"Arial",sans-serif;color:black;'>Where can I get further information?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#6C7794;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>If you have any questions about our use of cookies or other technologies, please&nbsp;contact us at:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'><br>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:#595959;'>GO PLATFORM</span></p>`;
export const customer = `<p style='margin-top:0cm;margin-right:0cm;margin-bottom:30.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:37.5pt;background:white;'><strong><span style='font-size:32px;font-family:"Arial",sans-serif;color:black;'>Customer and Candidates terms of use</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><em><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>Version v1.0 &ndash; January 1<sup>st</sup>, 2024</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><strong><u><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>Introduction</span></u></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>These GO PLATFORM Terms of Service (hereafter the <strong>&ldquo;Terms&rdquo;</strong>), outline the terms regarding Your use of the Services and are an integral part of the agreement between you (<strong>&ldquo;you&rdquo;</strong>, <strong>&ldquo;your,&rdquo;</strong> or <strong>&ldquo;Customer</strong>) and GO PLATFORM B.V. (<strong>&ldquo;GO PLATFORM&rdquo;</strong>, &ldquo;<strong>us</strong>&rdquo;, &ldquo;<strong>we</strong>&rdquo;, or &ldquo;<strong>our</strong>&rdquo;). You or GO PLATFORM may also be referred to individually as a &ldquo;Party&rdquo; and together as &ldquo;Parties&rdquo; in these Terms. An &ldquo;<strong>Aﬃliate</strong>&rdquo; means any company or other entity, whether or not a legal person, which directly or indirectly controls, is controlled by or is under joint control with that person. For the purpose of this definition, &ldquo;control&rdquo; means (a) any direct or indirect ownership of over 50% of the ownerships, or (b) in the absence of such ownership interest, the power to direct or cause the direction of the management and set the policies of such company or entity.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>These Terms are a legally binding contract between You and GO PLATFORM. If you do not agree with these Terms, do not register for a GO PLATFORM account, or purchase or use Services. By using or accessing the Services, or registering for a GO PLATFORM account, you agree to be bound by these Terms. If you use the Services on behalf of an entity, you agree to these Terms for that entity and represent and warrant to GO PLATFORM that you have the authority to bind that entity to these Terms, unless that entity has a separate paid contract in effect with GO PLATFORM, in which event the separate paid contract governs your use of the Services.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>In these Terms, we refer collectively to these Terms, the Data Processing Agreement and the applicable Service Level Agreement (&ldquo;<strong>SLA</strong>&rdquo;), other instructions and policies (&ldquo;<strong>Documentation</strong>&rdquo;) available on our website (&ldquo;<strong>Website</strong>&rdquo;) and applicable ordering documents (&ldquo;<strong>Order Form</strong>&rdquo;, as deﬁned below) as the &ldquo;<strong>Agreement</strong>.&rdquo;</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>1.<strong>&nbsp;Grant of access and use</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>1.1. Subject to the terms and conditions of the Agreement (including our receipt of applicable fees) GO PLATFORM hereby grants to you, and you accept, a limited, personal, non-exclusive, non-sublicensable, non-transferable, non-assignable license to access and use the Services solely for your own internal business purposes.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>1.2. Access and use of the Services are provided to any limited number of individuals taking the tests as provided as part of the Services as authorized by the Customer under the rights granted pursuant to the Agreement as specified in the Order Form (&ldquo;<strong>Candidates</strong>&rdquo;).</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>1.3. Subject to prior written approval by GO PLATFORM, your Affiliates may use the Services without entering into a separate Order Form by providing such Affiliate(s) a login ID and password to access and use the Services. The Agreement shall apply to each Affiliate with access to your account, and you are directly and primarily responsible for all access to and use of the Services by your Affiliates. References in these Terms to you, shall include a reference to your Affiliates.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>2.<strong>&nbsp;The Services</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>2.1. The &ldquo;<strong>Services</strong>&rdquo; include all products and services provided by GO PLATFORM that (a) have been ordered by the Customer under any applicable ordering document (either via the Website or otherwise) that specifies pricing and other commercial terms (&ldquo;<strong>Order Form</strong>&rdquo;); or (b) is used by you. Our Services are not intended for personal or private individual use but are designed and intended for commercial use only.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>2.2. GO PLATFORM will provide the Services in accordance with (a) the terms of the Agreement; and (b) in accordance with the applicable laws; and (c) the Information Security Measures.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>2.3. We may provide the Services, or a portion thereof, to you through our Affiliates in accordance with these Terms and any applicable Order Form(s). GO PLATFORM shall remain responsible at all times for any Services or part thereof, provided by our Aﬃliates.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>3.<strong>&nbsp;Your Account</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>3.1. In order to use our Services, it is required to create an account. To create an account, you (i) must be legally authorized to represent the company or business contracting our Services; and (ii) must review and accept these Terms on the Customer&rsquo;s behalf. To create an account, you must provide your email address and create a password. You agree to provide us with information that is accurate, complete, and current during your use of the Services. Failure to do so constitutes a breach of the Terms, which may result in termination of your account on our Service.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>3.2. You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password. You agree not to disclose your password to any third party.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>3.3. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account. You are responsible for preventing unauthorized access to or use of the Services through your account and will notify GO PLATFORM immediately of any such unauthorized access or use. GO PLATFORM is not liable for any loss or damage arising from unauthorized use of your account.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>4.<strong>&nbsp;User rights and responsibilities</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>4.1. In using the Services, you agree to use the Services only in accordance with these Terms and any applicable Documentation, Order Form(s), and applicable laws.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>4.2. You will be solely responsible for all use of the Services under your account including all applications, web domains, devices, and communication channels owned or controlled by you or owned or controlled by third parties and made available by you to the Candidates which access, use, interact with, or depend on the Services (each, a &ldquo;<strong>Customer Application</strong>&rdquo;).</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>4.3. You shall not: (a) duplicate any portion of the Services, or any documentation (except for your internal use); (b) modify, translate, decompile, reverse engineer, disassemble, adapt the Services, or attempt to derive the source code of the software offered through the Services; (c) use the Services, or allow the transfer, transmission, export, or re-export of the Services or portion thereof in violation of any applicable law, regulation or rule; (d) develop any software or service that is derived from the Services and materially similar in function to or competes with the Services; (e) bypass, hack or breach any security device or protection used by the Services or access or use the Services, with or without automated means (such as scrape, crawl or spider); (f) remove, modify or obscure any identification or proprietary or restrictive rights markings or notices from the Services or any component thereto; (g) input, upload, transmit, or otherwise provide to or through the Services, any information or materials that are unlawful or injurious, including the distribution or publication of information that is in violation of applicable law, contrary to public order or public morality, or contain, transmit, or activate any software, hardware, or other technology, device, or means, including any virus, worm, malware, or other malicious computer code; or (h) aid or assist any third parties in doing any of the above.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>4.4. You will not lease, (re)sell, (sub)license, assign, distribute, publish, transfer, or otherwise make available any Services to third parties (except Candidates), unless explicitly permitted under the Agreement.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>4.5. The Services can only be used by Candidates that are at least sixteen (16) years old.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>4.6. If you or any of the Candidates or any other person authorized to use the Services through your account violates the above restrictions or threatens to violate them, GO PLATFORM is entitled to intervene, without prior consultation with you, to terminate the violation or to prevent any imminent danger, for example by disabling the account of the relevant Candidates or your access to the Services. You will be liable for any damage caused by your use of the Services through your account in violation of these restrictions. GO PLATFORM is at all times entitled to report criminal offenses, directly related to your violation of the restrictions, it has discovered and shall have no liability for any damage to you that may result from such reports.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>5.<strong>&nbsp;Account suspension</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>5.1. We shall have the right to remove any inappropriate content from the Services, limit, or suspend your access to your account and the Services with immediate effect and without prior notice in the event that, in our reasonable determination if: (a) your use or the use of Candidates of the Services is for any unlawful, unauthorized or fraudulent purpose; (b) you or Candidates are in material breach of any provision of the Agreement; (c) your use of the Services is materially adversely threatening the security, availability or integrity of the Services or our ability to provide service to other customers; (d) our provision or your use of the Services is not permitted by applicable laws or regulations; (e) the account information you have provided is incorrect or incomplete; or (f) you are in breach of your payment obligations under the Agreement. If your account or use of the Services is suspended by us as a result of your actions or omissions pursuant to this article 5 or Article 8 (Fees and Payment Terms), GO PLATFORM does not have any liability for damages or losses (including any loss of data or proﬁts), or any other consequences that you may incur as a result. You will remain responsible for the Fees (as deﬁned below) during any suspension.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>6.<strong>&nbsp;Maintenance and Downtime, Modifications</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>6.1. The Services may occasionally become unavailable due to (a) the performance of scheduled or unscheduled maintenance, modifications, or upgrades; (b) hardware failures, or failures of third-party providers; (c) to mitigate or prevent the effects of any threat or attack to the Services or any other network or systems on which the Services rely; or (d) as necessary for legal or regulatory reasons. We will use reasonable efforts to communicate any scheduled service outages to you in advance.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>6.2. Unless specifically stipulated in an Order Form, the SLA or the Website, GO PLATFORM is not liable for any damages, losses (including loss of data or proﬁts), or any other consequences incurred as a result of unavailability of Services or the failure to provide notice of unavailability.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>6.3. We have the right to occasionally modify the features and operations of the Services. If we do, we will use reasonable efforts to inform you. We agree such changes to the Service will not materially diminish the overall features or functionality of the Services. Your continued use of the Services following the posting or notice of the changes will constitute your acceptance of such changes. If you do not agree to such changes, you must stop using the Services immediately. We will notify you in line with Section 15 (Amendments) if applicable legislation requires GO PLATFORM to provide you with specific notice of any such change.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>7.<strong>&nbsp;Free Plan and Beta Products</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>7.1. We may invite you to test out new or additional products or features of our Services that are not generally available to all of our customers (&ldquo;Beta Products&rdquo;) or use our Services free of charge (&ldquo;Free Plan Products&rdquo;).</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>7.2. Beta Products and Free Plan Products may contain errors and are provided for limited evaluation only.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>7.3. If you have access to Free Plan Products, we will make the Services available to you on a trial basis, free of charge, until the earlier of (a) the end of the free trial period, or (b) the start date of any purchased Service subscriptions ordered by you for such Services.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>7.4. Beta Products and Free Plan Products are provided &ldquo;AS IS&rdquo; without warranty of any kind, whether express, implied, statutory, or otherwise. GO PLATFORM specifically disclaims all implied warranties of merchantability, noninfringement and fitness for a particular purpose in relation to Beta Products and Free Plan Products.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>7.5. GO PLATFORM does not have any obligation to provide Beta Products and Free Plan Products to any customer or to our general customer base. GO PLATFORM is entitled to terminate or discontinue a Beta Product or Free Plan Product at any moment.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>8.<strong>&nbsp;Fees and Payment Terms</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>8.1. You shall pay GO PLATFORM all fees as set forth in the pricing section or in accordance with the applicable rates as published on the&nbsp;</span><strong><u><span style='font-size:16px;font-family:"Arial",sans-serif;color:blue;'>Website</span></u></strong><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>, unless agreed otherwise in writing by the Parties in the Order Form (the &ldquo;<strong>Fees</strong>&ldquo;).</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>8.2. The Services are billed on a subscription basis. You will be billed in advance on a recurring and periodic basis (&ldquo;<strong>Billing Cycle</strong>&rdquo;). Billing Cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select as indicated in the Order Form or the Website.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>8.3. All payment obligations are non-cancelable, and Fees and taxes, once paid, are non-refundable. Except as otherwise set forth in the applicable Order Form(s) and subject to Section 8.9 (payment disputes), you will pay the Fees due under these Terms in accordance with the following applicable payment method: (a) if you elect to remit the Fees using a credit card or PayPal, you represent and warrant that you are authorized to use that credit card or PayPal account, that any and all Fees may be billed to that credit card or PayPal account, and that payment of such Fees will not be declined; or (b) if you elect to receive invoices and GO PLATFORM approves you for the same, invoices will be sent to you at the frequency set forth in the applicable Order Form and you will pay the Fees due within twenty (20) days of the date of the invoice.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>8.4. You shall provide GO PLATFORM with accurate and complete billing information including full name, address, state, zip code, country, telephone number, and a valid VAT or applicable tax registration number and notify us of any changes to such information. By submitting the payment information, you automatically authorize GO PLATFORM to charge all Fees incurred through your account to any such selected payment instruments.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>8.5. Your use of the Services may be subject to specific usage limitations as listed in the Order Form or as otherwise listed in our Documentation (&ldquo;Usage Limitations&rdquo;). If your use of the Services exceeds the set Usage Limitations, you must pay the additional Fees as listed for such Service in the pricing section of the order form or on our&nbsp;</span><strong><u><span style='font-size:16px;font-family:"Arial",sans-serif;color:blue;'>Website</span></u></strong><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>&nbsp;(&ldquo;Overages&rdquo;). Overage will be billed and due in accordance with this Section 8 (Fees and Payment Terms) of these Terms and in accordance with the Billing Cycles.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>8.6. All Fees, Overage and other amounts payable by you under the Agreement are exclusive of taxes, duties, levies and similar assessments as well as any other costs including transaction costs or bank transfer fees. Without limiting the foregoing, you are responsible for all sales, use, and excise taxes, and any other similar taxes, duties, and charges of any kind imposed by any governmental or regulatory authority on any amounts payable by you hereunder, other than any corporate income taxes imposed on GO PLATFORM&rsquo;s income.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>8.7. GO PLATFORM is entitled to invoice you if for any reason the appropriate taxing authorities determine that you are not exempt from any taxes and GO PLATFORM is required to pay such taxes. Any applicable penalties or interest that might be imposed will be added to such invoices. The Fees shall in no event be decreased by any taxes and/or fees of any nature owed by you in connection with your purchase of the Services.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>8.8. If you fail to make any payment when due then, in addition to all other remedies that may be available:</span></p>
<ol style="margin-bottom:0cm;margin-top:0cm;" start="1" type="1">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;'>GO PLATFORM may charge interest on the past due amount at the rate of 1.5 % per month of the value of the applicable Fees, calculated daily and compounded monthly or, if higher, the maximum rate permitted under applicable law;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;'>You shall reimburse us for all costs incurred by GO PLATFORM in collecting any late payments or interest, including attorneys&rsquo; fees, court costs, and collection agency fees; and</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;color:black;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;'>If such failure continues for more than thirty (30) days following written notice thereof, we may suspend the performance of the Services until all past due amounts, and interest thereon, have been paid, without incurring any obligation or liability to you or any other person by reason of such suspension.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>8.9. If you do not agree with any invoice for Fees, you must notify us in writing within ten (10) days of the date of the respective invoice, failure of which shall result in acceptance of the invoice and forfeiture of your right to dispute the invoice. All undisputed fees remain due according to schedule.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>8.10. All amounts payable to GO PLATFORM under the Agreement shall be paid by you in full without any setoff, recoupment, counterclaim, deduction, debit, or withholding for any reason.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>8.11. We are entitled to increase the Fees annually for any contract year after the first Term with effect from the Renewal Term (as defined below). If you do not agree to any change in the Fees, you are entitled to terminate the Agreement before such modifications become effective. Your continued use of the Service after the modification of the Fees comes into effect constitutes your acceptance and agreement to pay the modified Fees.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>9.<strong>&nbsp;Intellectual Property and Data</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>9.1. You acknowledge and agree that we, and our licensors, as applicable, own all rights in and to the Services software, Documentation, the software including any related software application or component thereof, or to any associated materials or intellectual property, or in or to any enhancements, modifications, updates, or improvements of any of the foregoing (including any feedback you may provide), and all other materials created by us or provided by us to you as part of the Services under the Agreement. The Agreement does not grant to Customer any title or right of ownership in or to the Services, or any related software application or component thereof, or to any associated materials or intellectual property, including test content and questions, test algorithms and psychological profiling.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>9.2. You are prohibited to modify, translate, decompile, reverse engineer, disassemble, adapt the Services and the associated intellectual property rights, or attempt to abstract the source code from the software underlying the Services or the applications in any other way. You shall not remove or alter any of GO PLATFORM&rsquo;s proprietary or copyright notices, trademarks or logos contained in the Services.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>9.3. Any data that originates with a Candidate or that is provided as input via the use of the Services by a Candidate including the test results and scores derived from the Services (&ldquo;<strong>Candidate Date</strong>&rdquo;) shall be the exclusive property of the Candidate.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>9.4. All data or input that originates with you (which shall include candidate custom test questions uploaded by you as part of your use of the Services) and materials generated by you via the use of the Services with such data under the Agreement shall remain, as between Customer and GO PLATFORM, the exclusive property of Customer (collectively, &ldquo;<strong>Customer Data</strong>&rdquo;). Candidate Data is explicitly excluded from Customer Data.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>9.5. All intellectual property rights in and to any data that is derived from the use of the Services, however excluding Customer Data, and, subject to applicable law, any de-identified or anonymized aggregated data, shall be the exclusive property of GO PLATFORM (&ldquo;<strong>GO PLATFORM Data</strong>&rdquo;). GO PLATFORM hereby grants to you, and you accept, a limited-term, personal, non-exclusive, non-sublicensable, non-transferable, non-assignable license to access and use GO PLATFORM Data, including results derived from Candidate Data, solely for your own use of the Services in accordance with the Agreement.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>9.6. You grant us and our Affiliates authorized by you to provide Services under these Terms the right to use and process Customer Data only to the extent necessary to provide the Services in accordance with the Terms and the Data Processing Agreement. The terms of the Data Processing Agreement are incorporated into these Terms as an Annex. Your agreement to these Terms constitutes agreement to the Data Processing Agreement.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>10.<strong>&nbsp;Representations, Warranties, and Disclaimer</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>10.1. Each Party shall at all times comply with all applicable laws, rules and regulations in the performance of the Agreement.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>10.2. Each Party represents and warrants that it has been duly authorized to enter into the Agreement and it is entitled to perform its obligations and provide the licenses hereunder.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>10.3. Customer represents and warrants to have obtained all the required permissions or consents to provide Customer Data to GO PLATFORM s for use and disclosure in accordance with the Agreement.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>10.4. Customer represents and warrants (a) to use the Services only for lawful purposes and in a manner consistent with these Terms; (b) not to use the Services to assess candidates for any jobs which are unlawful, unsafe, offensive, discriminatory, or inappropriate; and (c) not to use the Services to solicit information from candidates that could be used to discriminate against them.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>10.5. GO PLATFORM warrants that the Services will substantially conform in all material respects in accordance with the applicable Documentation and that the Services shall not contain or transmit any (a) virus, trojan horse, worm, backdoor, or other software or hardware devices the effect of which is to permit unauthorized access to, or to disable, erase, or otherwise harm, any computer, systems, or software; or (b) time bomb, drop dead device, or other software or hardware device designed to disable a computer program automatically with the passage of time or under the positive control of any person. GO PLATFORM further warrants and undertakes that: (a) it has and will maintain all necessary licenses, consents, and permission necessary for the performance of its obligations under the Agreement; and (b) it will cooperate with Customer in all matters relating to the Services. You will provide prompt written notice of any non-conformity. As Customer&rsquo;s sole and exclusive remedy and GO PLATFORM&rsquo;s entire liability for any breach of our warranties, GO PLATFORM will at our sole discretion either v(a) use reasonable efforts to fix, provide a workaround, or otherwise correct the defect or, if GO PLATFORM is unable to do so, (b) refund the Fees paid to for such allegedly defective Services for the period commencing from the receipt of your default notice for the remainder of the time period during which the material failure affected the Services.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>10.6. Except for the warranties expressly provided by us in Sections 10.1 and 10.5, Customer expressly acknowledges and agrees that, to the maximum extent permitted by applicable law, the Services are provided &ldquo;as is&rdquo; with all faults and without warranty of any kind, and GO PLATFORM hereby disclaims all warranties and conditions with respect to the Services, either express, implied or statutory, including, but not limited to, the implied warranties and/or conditions of merchantability, of satisfactory quality, of fitness for a particular purpose, or accuracy.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>11.<strong>&nbsp;Indemniﬁcation</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>11.1. We will defend, indemnify and hold Customer harmless against all damages, ﬁnes, penalties, settlement amounts pre-approved by us, costs, expenses, taxes, and other liabilities (including reasonable attorneys&rsquo; fees) incurred or awarded against you in connection with any unaffiliated third-party claim, action, demand, suit, or proceeding made or brought against you and your Affiliates, as well as their individual officers, directors, and employees that your use of the Services in accordance with the Agreement infringes copyright, registered trademark, issued patent, or other intellectual property right of such third party (&ldquo;<strong>Infringement Claim</strong>&ldquo;), on written demand. We reserve the right to either (a) alter the Services to make them non-infringing, or (b) terminate the infringing Services and refund you any Fees, in the event of an Infringement Claim.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>11.2. Any Infringement Claim resulting from or arising out of (a) your use of the Services in violation of the Agreement; (b) the combination of the Services with other applications, products, or services where the Services would not by themselves be infringing; or (c) Beta Products and Free Plan Products do not give rise to any liability or obligation on our part under Section 11.1.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>11.3. Customer will defend, indemnify and hold GO PLATFORM harmless against all damages, ﬁnes, penalties, costs, expenses, taxes, and other liabilities (including reasonable attorneys&rsquo; fees) incurred or awarded against GO PLATFORM, our Affiliates, officers, directors and personnel in connection with any claim by an unaffiliated third party alleging or arising out of the (a) breach of your obligations under the Agreement; (b) failure to obtain any appropriate license or other permissions, regulatory certifications, or approvals associated with technology or data provided by Customer including Customer Data; (c) failure to comply with your obligations in violation of any applicable law, including data privacy laws; (d) infringement or misappropriation of intellectual property rights of third parties; and (e) breach of confidentiality.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>11.4. The obligation to provide indemnification under the Agreement shall be contingent upon the party seeking indemnification (i) providing the indemnifying party with prompt written notice of any claim for which indemnification is sought, (ii) allowing the indemnifying party to control the defense and settlement of such claim, provided however that the indemnifying party agrees not to enter into any settlement or compromise of any claim or action in a manner that admits fault or imposes any restrictions or obligations on an indemnified party without that indemnified party&rsquo;s prior written consent which will not be unreasonably withheld, and (iii) cooperating fully with the indemnifying party in connection with such defense and settlement.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>11.5. This Section 11 (Indemniﬁcation) states the sole liability of the indemnifying Party to, and the exclusive remedy of the indemnified Party against, the other Party for any third-party claims.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>12.<strong>&nbsp;Limitation of Liability</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>12.1. To the greatest extent permitted by applicable law, each Party&rsquo;s total accumulated liability to the other or to any third party for any direct loss, damages, costs or expenses whether in strict liability, negligence, contract or otherwise relating to this Agreement, shall be limited to the aggregate fees paid or payable by Customer to GO PLATFORM during the twelve-month period prior to the occurrence of the initial event giving rise to a claim.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>12.2. Neither Party shall be liable to the other for any consequential damage and indirect damage, including but not limited to loss, lost sales or lost profits, consequential, incidental, special, punitive, and/or contingent damages whatsoever, (whether in contract, tort (including negligence), strict liability, warranty or otherwise), even if such party knew or should have known of the possibility of such damages.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>12.3. GO PLATFORM shall not be liable for Beta Products and Free Plan Products nor for damage, destruction or loss of data or documents (including Customer Data) resulting from the use of the Services.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>12.4. The limitations of liability do explicitly not apply to: (a) Customer&rsquo;s breach of Section 4 (User Rights and Responsibilities); (b) Customer&rsquo;s breach of Section 8 (Fees and Payment Terms); or (c) the obligations under Section 11 (Indemniﬁcation).</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>13. <strong>Confidentiality</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>13.1. In connection with this Agreement, each Party may have access to or be exposed to information of the other Party that is not generally known to the public, such as software, product plans, pricing, marketing and sales information, customer lists, &ldquo;know-how,&rdquo; or trade secrets, which may be designated as confidential or which, under the circumstances surrounding disclosure, ought to be treated as confidential, including Customer Data (collectively, &ldquo;<strong>Confidential Information</strong>&rdquo;).</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>13.2. Confidential Information may not be shared with third parties unless such disclosure is to the receiving Party&rsquo;s personnel, including employees, agents, and authorized subcontractors, on a &ldquo;need-to-know&rdquo; basis in connection with this Agreement, so long as such personnel have agreed in writing to treat such Confidential Information under terms at least as restrictive as those herein. Each Party agrees to take the necessary precautions to maintain the confidentiality of the other Party&rsquo;s Confidential Information by using at least the same degree of care as such Party employs with respect to its own Confidential Information of a similar nature, but in no case less than a reasonable standard of care to maintain confidentiality.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>13.3. The foregoing shall not apply to information that: (a) was known by the receiving party prior to its receipt from the disclosing party or is or becomes public knowledge through no fault of the receiving party; or (b) is rightfully received by the receiving party from a third party without a duty of confidentiality. If a receiving party is required by a court or government agency to disclose Confidential Information, the receiving party shall provide reasonable advance notice to the disclosing party before making such disclosure to enable the disclosing party to see an appropriate protective order or other such remedy.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>14.<strong>&nbsp;Term and termination</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>14.1. The Agreement including these Terms commence on the date when accepted or on the date as indicated in the Order Form and will continue until all Order Forms or Services used by Customer on the Website entered into under these Terms have expired or been terminated.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>14.2. Your initial subscription duration is specified when the Services are activated through the Website or in the applicable Order Form (&ldquo;<strong>Initial Term</strong>&rdquo;). Unless otherwise provided on the Website or in the applicable Order Form, your Subscription will automatically renew for additional successive periods of equal duration to the Initial Term (each, a &ldquo;<strong>Renewal Term</strong>&rdquo;, and together with the Initial Term, the &ldquo;<strong>Term</strong>&rdquo;) unless canceled by you or GO PLATFORM. If you do not agree to the renewal, you are entitled to terminate the renewal of the Agreement either through the Website or by contacting the contact help center up to the last day of the Term. Your continued use of the Service constitutes your acceptance and agreement to the Renewal Term.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>14.3. The applicable fee for any Renewal Term will be determined using the then-current list price applicable on the Website for such renewed Services unless a different renewal pricing is speciﬁed in the Order Form.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>14.4. Either Party may terminate the Agreement and any Order Form (in whole or in part) by providing the other Party with not less than ten (10) days&rsquo; prior written notice in the event the other Party materially breaches any provision of this Agreement. If the breaching Party fails to cure the material breach within a ten (10) day period following the notice of default, the non-breaching Party may terminate this Agreement effective at the end of the ten (10) day period, notwithstanding any other provision in this Agreement. Regarding a material breach of Customer, GO PLATFORM may, in addition to termination, suspend the provision of certain Services, close your accounts, and/or prohibit Customer from creating any new accounts.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>14.5. In no event will termination of the Agreement or Order Form relieve Customer of any payment obligation of the Fees payable prior to the effective date of termination.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>14.6. Either Party may terminate this Agreement by written notice with immediate effect in the event the other Party becomes insolvent, or generally unable to pay its debts as they become due or makes an assignment for the benefit of its creditors or seeks relief under any bankruptcy, insolvency or liquidation proceedings.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>14.7. In the event of any expiration or termination of the Agreement: (a) GO PLATFORM will invoice Customer for any accrued but unbilled amounts, and Customer shall promptly pay any then outstanding and unpaid amounts including any accrued but unbilled amounts owed under the Agreement; (b) Customer shall immediately cease all use of the Services and return or purge any and all components thereof, including returning or destroying or causing to be destroyed any and all copies of the Documentation, notes and other materials comprising or regarding the Services and any GO PLATFORM Data; (c) GO PLATFORM will suspend access to the Services and Customer shall no longer have access to GO PLATFORM&rsquo;s platform including its historic assessments; and (d) GO PLATFORM delete to Customer all Customer Data, all Customer Confidential Information and any other material, equipment or information that is proprietary to Customer within sixty (60) days after the effective date of expiration or termination, unless it is required to store such data longer as required by applicable law or as required to prosecute or defend a legal claim, in which case such information will only be retained for as long as required to resolve the claim.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>14.8. The following sections and paragraphs shall survive the expiration or termination of this Agreement under Section 14 (Term and Termination): Section 8 (Fees and Payment Terms), Section 9 (Intellectual Property and Data), Section 13 (Conﬁdentiality); Section 10.5 (Disclaimer), Section 11 (Indemniﬁcation), Section 12 (Limitation of Liability), Section 16 (General) as well as the Data Processing Agreement.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>15.<strong>&nbsp;Amendments</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>15.1. GO PLATFORM is entitled to amend these Terms from time to time. We will use reasonable efforts to notify you of any material changes by posting an announcement on the Website or by email. To the greatest extent permitted by applicable law, the new Terms will take immediate effect, and your continued use of the Services following our posting or notice of the changes will constitute your acceptance of the updated Terms.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>15.2. If we are required by applicable law to give additional notice, changes will automatically take effect regarding your use of the relevant Services upon the expiry of such notice period (unless you terminate during that period) or upon your earlier acceptance of such changes. If you have a right under applicable law to terminate this Agreement upon receipt of such notice, you will not be charged a fee for early termination where you exercise that right under applicable law, but any fees previously paid by you are non-refundable and any fees owing continue to remain due and payable.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>16.<strong>&nbsp;Miscellaneous</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>16.1. <u>Compliance with Law Assurances.</u> Both Parties warrant to comply with all applicable anti-corruption, anti-money laundering, sanctions, export controls, and other international trade laws, regulations, and governmental orders of the European Union, the United Kingdom, the United States of America, the United Nations, or any other relevant governmental authority, including obtaining all necessary licenses and/or government approvals. Parties will promptly notify each other in writing of any actual or potential violation of such applicable laws and regulations in connection with the use of the Services and Parties shall take all appropriate actions to remedy or resolve such violations, including any actions requested by the other Party.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>16.2. <u>No Class Actions.</u> To the greatest extent permitted by applicable law, neither Customer nor GO PLATFORM shall be entitled to join or consolidate claims by or against other customers or pursue any claim as a representative of a class action or in a private attorney general capacity.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>16.3. <u>US Government Terms.</u> The Services, including any related software and technology, are provided solely in accordance with these Terms for United States government end use. If you (or any users of your Customer Application) are an agency, department, or other entity of the United States government, the use, duplication, reproduction, release, modiﬁcation, disclosure, or transfer of the Services, or any related documentation, is restricted by these Terms. All other use is prohibited and no other rights other than those provided in these Terms are conferred.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>16.4. <u>Independent Contractors.</u> The Parties are independent contractors. No provision of this Agreement will or shall be deemed to create an association, trust, partnership, joint venture or other entity or similar legal relationship between GO PLATFORM and Customer, or impose a trust, partnership or fiduciary duty, obligation, or liability on or with respect to such entities. Neither Party will have any rights, power, or authority to act or create an obligation, express or implied, on behalf of another party except as specified in the Agreement.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>16.5. <u>Force Majeure.</u> Neither Party shall be liable to the other for any failure to perform any of its obligations (except payment obligations) under the Agreement during any period in which such performance is delayed by circumstances beyond its reasonable control, such as fire, flood, war, embargo, strike, riot, terrorism, epidemic or pandemic, or the intervention of any governmental authority (a &ldquo;Force Majeure&rdquo;). In such event, however, the delayed Party must promptly provide the other Party with written notice of the Force Majeure. The delayed Party&rsquo;s time for performance will be excused for the duration of the Force Majeure, but if the Force Majeure event lasts longer than 90 days, then the other Party may immediately terminate, without any liability, in whole or in part, the Agreement by giving written notice to the delayed Party.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>16.6. <u>Transferability and subcontracting.</u> Neither all nor any part of Customer&rsquo;s rights or obligations under this Agreement are assignable or transferable by Customer, whether directly or indirectly, without the prior written consent of GO PLATFORM, and any attempt to do so shall be void, except in case of merger, acquisition or sale of majority of assets. GO PLATFORM has the right to freely assign all or part of its rights and obligations under the Agreement or to make use of the services of third parties by subcontracting. Subject to the foregoing, the Agreement shall be binding upon and inure to the benefit of the parties and their respective successors and permitted assigns.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>16.7. <u>Entire Agreement.</u> The Agreement constitutes the entire agreement between you and us with respect to its subject matter and supersedes all prior oral and written understandings, communications, or agreements. General terms and conditions of Customer are not applicable and expressly excluded.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>16.8. <u>Severability.</u> If any provision of the Agreement should be found to be void or unenforceable, such provision will be stricken or modified, but only to the extent necessary to comply with the law, and the remainder of the Agreement will remain in full force and will not be terminated.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>16.9. <u>Notices.</u> Any notice, request, demand, or other communication to be provided under this Agreement shall be in writing, and shall be sent by the email addresses provided by each Party, or at such other address as a Party may designate by written notice to the other Party.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>16.10. <u>Headings.</u> The section headings in this Agreement are inserted for convenience of reference only and shall not affect the meaning or interpretation of the Agreement.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>16.11. <u>Publicity.</u> You grant us the right to use your name, logo, and a description of your use case to refer to you on our website, customer lists, or marketing or promotional materials, subject to your standard trademark usage guidelines expressly provided to us.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>16.12. <u>Execution.</u> This Agreement may be executed in one or more counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same instrument. Each Party agrees that this Agreement and any other documents to be delivered in connection herewith may be electronically signed and that any electronic signatures appearing on this Agreement or such other documents are the same as handwritten signatures for the purposes of validity, enforceability, and admissibility.</span></p>
<p style='margin-top:30.0pt;margin-right:0cm;margin-bottom:27.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:22.5pt;background:white;'><span style='font-size:24px;font-family:"Arial",sans-serif;color:black;'>17.<strong>&nbsp;Governing law</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:18.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>17.1. The Agreement including these Terms shall be governed by the laws of the Qatar. The United Nations Convention on Contracts for the International Sale of Goods is explicitly excluded.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:21.0pt;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>17.2. The Parties agree that any dispute arising out or relating to the Agreement shall be brought exclusively in the appropriate courts in Amsterdam, the Qatar.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;background:white;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>`;
