import axios from "axios";

const state = {
    assessements: [],
};

const getters = {
    AllAssessements: (state) => state.assessements,
};

const actions = {
    async fetchAssessements({ commit }) {
        const headers = { "Content-Type": "application/json" };
        const response = await axios.get("https://server.go-platform.com/data/assessemnts", {
            headers,
        });
        commit("setAssessements", response.data);
    },
    async addAssessement({ commit }, assessement) {
        try {
            const newAssessement = await axios.post("https://localhost:3000/post/assessement", { assessement });
            commit("newCandidate", newAssessement);
        } catch (error) {
            console.log(error);
        }
    },
    deleteAssessement({ commit }, id) {
        commit("removeAssessement", id);
    },
    updateAssessement({ commit }, upadtedAssessement) {
        commit("updateAssessement", upadtedAssessement);
    },
};

const mutations = {
    setAssessement: (state, assessements) => (state.AllAssessements = assessements),
    newAssessement: (state, assessement) => state.assessements.unshift(assessement),
    removeAssessement: (state, id) => state.assessements.splice(id, 1),
    updateAssessement: (state, upadtedAssessement) => {
        const index = state.assessements.findIndex((assessement) => assessement.id === upadtedAssessement.id);
        if (index !== -1) {
            state.assessements.splice(index, 1, upadtedAssessement);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
