import axios from "axios";

const state = {
    candidates: [],
};

const getters = {
    AllCandidates: (state) => state.candidates,
};

const actions = {
    async fetchCandidates({ commit }) {
        const headers = { "Content-Type": "application/json" };
        const response = await axios.get("https://server.go-platform.com/data/candidates", {
            headers,
        });
        commit("setCandidates", response.data);
    },
    async addCandidate({ commit }, candidate) {
        try {
            const newCandidate = await axios.post("https://server.go-platform.com/post/tasks", { candidate });
            commit("newCandidate", newCandidate);
        } catch (error) {
            console.log(error);
        }
    },

    deleteCandidate({ commit }, id) {
        commit("removeCandidate", id);
    },
    updateCandidate({ commit }, upadtedCandidate) {
        commit("updateCandidate", upadtedCandidate);
    },
};

const mutations = {
    setCandidates: (state, candidates) => (state.AllCandidates = candidates),
    newCandidate: (state, candidate) => state.candidates.unshift(candidate),
    removeCandidate: (state, id) => state.candidates.splice(id, 1),
    updateCandidate: (state, updatedCandidate) => {
        const index = state.candidates.findIndex((candidate) => candidate.id === updatedCandidate.id);
        if (index !== -1) {
            state.candidates.splice(index, 1, updatedCandidate);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
