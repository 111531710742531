const state = {
    logged: false,
};
const mutations = {
    setLogged: (state, value) => {
        state.logged = value;
    },
};

export default {
    state,
    mutations,
};
